<template>
  <div>
    <div class="preloader" v-if="loading">
      <div class="circular-spinner">
        <img src="../assets/images/shiba-loading.png" height="200">
      </div>
      </div>
		<div class="fixedflexcn loginbg paddinglogin">
	<div class="incontainerlg">
		<div class="p-0 px-1 px-md-2 px-lg-4 flexcn">
			<div class="bginputlogin">

				<div class="logologin">
				<img src="../assets/images/logo.png" style="width:200px;">
				</div>
				<div class="inbgbeforelogin">

				<div class="fleximgbanner">
					<img src="../assets/images/LoGoUfa.png">
					<img src="../assets/images/LoGoEDM.png">
					<img src="../assets/images/LoGobetflix.png">
					<img src="../assets/images/LoGoAMB.png">
					<img src="../assets/images/LoGoIMI.png">
				</div>

				<!-- LOGIN Section -->
				<div id="loginsection" class="frontsection animate__animated animate__fadeIn" style="display:block;">
				   <div class="insidectloginmb">
				      <div class="headerlogin my-2">
				         <h2>เข้าสู่ระบบ</h2>
				      </div>
				      <form>
					  	<div class="form-group">
							<div id="notification" style="text-align: center;"></div>
						</div>
				         <div>
				            <div class=" form-group my-2">
				               <div>
				                  <label> รหัสผู้ใช้งาน</label> 
				                  <div class="el-input my-1">
				                     <i class="fas fa-user"></i>
				                     <input type="number" placeholder="กรอกเบอร์โทรศัพท์" id="Username" name="Username" v-model="mobile" class="inputstyle" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10">
				                  </div>
				               </div>
				            </div>
				            <div class="form-group my-4">
				               <div>
				                  <label>รหัสผ่าน</label> 
				                  <div class="el-input my-1">
				                     <i class="fas fa-lock"></i>
				                     <input type="password" placeholder="รหัสผ่าน" id="Password" name="Password" v-model="password" class="inputstyle">
				                  </div>
				               </div>
				            </div>
				         </div>
				      </form>
				      <div class="row m-0">
				         <div class="col-6 p-0 pr-1">
				            <button type="button" class="loginbtn active mt-3" @click="login()" >
				            <span>
				            	เข้าสู่ระบบ
				            </span>
				            </button>
				         </div>
				         <div class="col-6 p-0 pl-1">
				            <button type="button" class="loginbtn regisbtn mt-3" @click="gotoRegister()">
				            <span>
				            	สมัครสมาชิก
				            </span>
				            </button>
				         </div>
				      </div>
			
				   </div>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="sideline">
  <a href="https://lin.ee/xiH5wuH" target="_blank">
   <img src="../assets/images/contactus.png">
  </a>
</div>
  </div>
  </div>
</template>

<script>
import queryString from 'querystring'
import firebase from 'firebase/app'
import 'firebase/database'
import router from './../router'
const axios = require('axios').default
// import OAuthRequest from '@team-decorate/src/Entities/OAuthRequest'
const qs = require('qs')
// import OAuthResult from '@team-decorate/src/Entities/OAuthResult'
export default {
  name: 'Login',
  async created () {
    const { code } = queryString.parse(window.location.search.replace('?', ''))
    if (!code) return
    const result = await this.getToken(code)
    if (result && result.access_token) {
      const { data } = await this.getProfile(result.access_token)
      this.checkLineLogin(data)
    }
    // if(this.friendRequired) {
    //     const flag = await this.checkFriend(result.token)
    //     if(!flag) {
    //         this.error = this.friendErrorTest
    //     }
    // }
    // if(!this.error) {
    //     const response = Object.assign(data, result.getPostable())
    //     console.log(response);
    // }
  },
  data () {
    return {
      pictureUrl: '',
      name: '',
      logo: '',
      login_color: '#1e6ee8',
      error: '',
      link: '',
      mobile: '',
      password: '',
      web: [],
      change: false,
      loading: false,
      clientId: '1656935750',
      callbackUri: 'https://zodiac-z.co/login',
      clientSecret: '4b4c6d35256b0034244f1a0056ddb640',
      addFriend: false,
      friendRequired: false,
      friendErrorTest: '',
      forgot_mobile:'',
      forgot_account:'',
      forgot_password:'',
      forgot_password2:'',
    }
  },
  mounted () {
    // this.getSetting();
    $('body').on('click', '.showpass', function () {
      let isShow = $(this).attr('data-isshow')
      const pType = isShow == 'false' ? 'text' : 'password'
      isShow = isShow == 'false' ? 'true' : 'false'
      $(this).attr('data-isshow', isShow)
      if (pType == 'text') {
        $(this).find('i').attr('class', 'fa fa-eye')
      } else {
        $(this).find('i').attr('class', 'fa fa-eye-slash')
      }
      $('#password').attr('type', pType)
    })
    $('body').on('click', '#btn-forgotModal', function () {
      $('.msformforgot fieldset:not(:first-of-type)').css({ display: 'none' })

      const fEle = $('.msformforgot fieldset:first-child')
      fEle.css({ display: 'block', opacity: 1 })
    })
  },
  methods: {
    forgotPassword() {
      if(this.forgot_mobile && this.forgot_account && this.forgot_password && this.forgot_password2){
        if(this.forgot_password.trim() !== this.forgot_password2.trim()){
          this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'โปรดตรวจสอบข้อมูลรหัสให้ถูกต้องค่ะ!!!' })
        }else{
          axios({
            method: 'post',
            url: 'https://api.zodiac-z.co/api/forgotpassword',
            data: {
              mobile: this.forgot_mobile.trim(),
              account: this.forgot_account.trim(),
              newpassword: this.forgot_password.trim()
            }
          }).then(async res => {
              if (res && res.data && res.data.status) {
                $("#forgotModal").modal('hide')
                this.$swal({ icon: 'success', title: 'ข้อความ!', text: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้วค่ะ' })
              }else if(res.data.error){
                this.$swal({ icon: 'error', title: 'ข้อความ!', text: res.data.error })
              }
          });
        }
      }else{
        this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'โปรดระบุข้อมูลให้ครบด้วยค่ะ!!!' })
      }
    },
    checkLineLogin (data) {
      this.loading = true
      axios({
        method: 'post',
        url: 'https://api.zodiac-z.co/api/loginline',
        data: {
          userId: data.userId,
          pictureUrl: data.pictureUrl
        }
      }).then(async res => {
        if (res && res.data && res.data.status) {
          firebase.auth().signInWithEmailAndPassword(res.data.mobile.trim().toLowerCase() + '@zodiac-z.co', res.data.password.trim()).then(async (res2) => {
            if (res2.user) {
              localStorage.setItem('userData', JSON.stringify(res.data))
              this.loading = false
              router.replace('/')
            } else {
              this.error = 'โปรดติดต่อแอดมิน @zodiac-z'
            }
            this.loading = false
          }).catch(() => {
            this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'เบอร์โทรศัพท์ / รหัสผ่าน ไม่ถูกต้องค่ะ!!!' })
            this.loading = false
          })
        }else{
          localStorage.setItem('userId', JSON.stringify(data))
          router.replace('/register')
          this.loading = false
        }
      })
    },
    openLine () {
      window.location = 'https://lin.ee/xiH5wuH'
    },
    login () {
      if (!this.mobile) {
        this.$swal({ icon: 'warning', title: 'ข้อความ!', text: 'โปรดระบุเบอร์มือถือค่ะ!!!' })
        $('#mobile').focus()
      } else if (!this.password) {
        this.$swal({ icon: 'warning', title: 'ข้อความ!', text: 'โปรดระบุ Password ค่ะ!!!' })
        $('#pass').focus()
      } else {
        this.loading = true
        axios({
          method: 'post',
          url: 'https://api.zodiac-z.co/api/login',
          data: {
            mobile: this.mobile,
            password: this.password
          }
        }).then(async res => {
          if (res.data && !res.data.status) {
            this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'เบอร์โทรศัพท์ / รหัสผ่าน ไม่ถูกต้องค่ะ!!!' })
            $('#mobile').focus()
            this.loading = false
          } else {
            firebase.auth().signInWithEmailAndPassword(this.mobile.trim().toLowerCase() + '@zodiac-z.co', this.password.trim()).then(async (res2) => {
              if (res2.user) {
                localStorage.setItem('userData', JSON.stringify(res.data))
                this.loading = false
                router.replace('/')
              } else {
                this.error = 'โปรดติดต่อแอดมิน'
              }
              this.loading = false
            }).catch(() => {
              this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'เบอร์โทรศัพท์ / รหัสผ่าน ไม่ถูกต้องค่ะ!!!' })
              this.loading = false
            })
          }
        })
      }
    },
    lineLogin () {
      const url = 'https://access.line.me/oauth2/v2.1/authorize'
      let params = {
        response_type: 'code',
        client_id: this.clientId,
        redirect_uri: this.callbackUri,
        state: Math.random().toString(32).substring(2),
        scope: 'openid profile'
      }
      if (this.addFriend) {
        params = Object.assign(params, {
          prompt: 'consent',
          bot_prompt: 'aggressive'
        })
      }
      window.location.href = `${url}?${queryString.stringify(params)}`
    },
    async getToken (code) {
      const data = qs.stringify({
        grant_type: 'authorization_code',
        code,
        redirect_uri: this.callbackUri,
        client_id: this.clientId,
        client_secret: this.clientSecret
      })
      let response
      await axios({
        method: 'post',
        url: 'https://api.line.me/oauth2/v2.1/token',
        data: data
      }).then(async res => {
        if (res.status !== 200) {
          this.error = 'โปรดติดต่อแอดมิน'
        } else if (res.data) {
          response = res.data
        }
      })
      return response
    },
    async getProfile (token) {
      return await axios.get('https://api.line.me/v2/profile', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    },
    async checkFriend (token) {
      const { data } = await axios.get('https://api.line.me/friendship/v1/status', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return data.friendFlag
    },
    checkShowPassword () {
      var x = document.getElementById('pass')
      if (x.type === 'password') {
        x.type = 'text'
      } else {
        x.type = 'password'
      }
    },
    gotoRegister () {
      router.replace('/register')
    }
  }
}
</script>
<style lang="scss" scoped>
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: #275EFE;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }
}
</style>
<style scoped>
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.ewHin {
    background: rgb(0, 187, 0);
    color: rgb(255, 255, 255);
    height: 50px;
    min-width: 180px;
    border-radius: 25px;
    padding-left: 34px;
    padding-right: 34px;
}
.cCsozd {
    display: inline-block;
    padding: 0.5rem 1rem;
    height: 44px;
    font-size: 1em;
    line-height: 1.5;
    border: none;
    border-radius: 0.25rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
}
.ewHin .icon {
    height: 18px;
    margin: 0px 0.5rem;
    vertical-align: sub;
    border: 0px;
}
/*
Theme Name: Twenty Twenty
Theme URI: https://wordpress.org/themes/twentytwenty/
Author: the WordPress team
Author URI: https://wordpress.org/
Description: Test
Version: 1.3
Requires at least: 5.0
Tested up to: 5.4
Requires PHP: 7.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: twentytwenty
This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.
*/


@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
body {
  background: rgb(0, 0, 0);
  font-family: 'Kanit', sans-serif;
  position: relative;
  color: white;
  background: #014410;
  overflow-x: hidden;
  height: 100%;
}


a{
  color: white;
}
a:hover{
  color: white;
  text-decoration: none;
}


label{
  margin-bottom: 0;
}

.cursorp{
  cursor: pointer;
}

footer{
  background-color: maroon;
  color: white;
  display: none;
}

        .wrapper-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  outline: none;
    position: absolute;left: 10px; top: 8px;
    z-index: 1000;
    transition: all .5s;
}
.wrapper-menu2 {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  outline: none;
}


.wrapper-menu.hamopen{
        position: fixed;left: 200px; top: 8px;
}
.line {
  fill: none;
  stroke: #fff;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
   animation: lineone 2s ease 0s infinite normal none;
}
@keyframes lineone {
        0.0%{
    }
    50.1%{
        transform: scale3d(1.2,1,1) translate(-10px);
    }
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 3;
   animation: linetwo 2s ease 0s infinite normal none;
}
@keyframes linetwo {
        0.0%{

    }
    50.1%{
        transform: scale3d(.8,1,1) translate(10px);
    }
}



.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
   animation: linetree 2s ease 0s infinite normal none;
}
@keyframes linetree {
        0.0%{
    }
    50.1%{
        transform: scale3d(1.2,1,1) translate(-10px);
    }
}
.open .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
  animation: yourAnimation 3s ease 0s infinite normal none;
}
.open .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 3;
  animation: yourAnimation 3s ease 0s infinite normal none;
}
.open .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
  animation: yourAnimation 3s ease 0s infinite normal none;
}

@keyframes yourAnimation {
    0.0%{
    }
    50.1%{
    }
}


.fixedflexcn{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}
.flexcn{
  flex-direction: column;
  flex-wrap: nowrap;
}





/* Login  */
.frontsection{
  display: none;
}
.fleximgbanner{
 text-align: center;
 margin: 1px 0;
 margin-top: 25px;
}
.fleximgbanner img{
  width: 100%;
  max-width: 60px;
  margin: 5px 5px;
}
@media (max-width:440px){
  .fleximgbanner img{
  max-width: 13vw;
}
}
.loginbg{
  background: radial-gradient(transparent, #000000d1), url(../assets/images/01.jpg) center center;
  background-size: cover !important;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
}

.linelogin button{
  width: 100%;
  margin-top: 13px;
  border: none;
  border-radius: 10px;
  padding: 10px 0;
  background: #00b803;
  color: white;
}
@media (max-width:767px){
  .loginbg{
}
}
.bginputlogin{
  padding: 30px;
  border-radius: 19px;
  font-size: 15px;
  font-weight: 200;
  position: relative;
  transition: all .4s;
}
.inbgbeforelogin{
  z-index: 99;
  position: relative;
}
.bginputlogin .logologin{
  z-index: 99;
  position: relative;
}
.bginputlogin::before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      bottom: 0;
      left: 0;
      /* border: 3px solid gold; */
      border-radius: 10px;
      /* background: url(../assets/images/mini.jpg) center center;  */
      background-repeat: no-repeat;
      background-size: 100% 100%;


      /* background-origin: border-box; */
      /* background-clip: content-box,border-box; */
}
.paddinglogin{
  padding: 20px 20px;
  height: 100vh;
}
.leftcartoon img{
  width: 100%;
  -webkit-mask-image: -webkit-linear-gradient(268deg, #ffffffff 80%, #00000000 100%);
  animation: zoomout 20s ease 0s infinite normal none;
}
@keyframes zoomout {
    0.0%{
    }
    100%{
    }
    49.6%{
        transform: scale(1.05);
    }
}

.logologin {
  text-align: center;
}
.logologin img{
  width: 100%;
  max-width: 500px;
  height: auto;
  animation: logoanimate 10s ease 0s infinite normal none;
  /* box-shadow: 0 0 10px #3cb371; */
  border-radius: 14px;
  margin: 10px 0;
}
@media (max-width:767px){
  .logologin img{
    max-width:320px;
}
}
@keyframes logoanimate {
 0.0%{
}
50.0%{
  transform: scale3d(1.1,1.1,1.1) ;
}
}
.regislogo img{
  width: 90px;
}

.incontainlogin{
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  color: #ffe57b;
  overflow: auto;
  padding-bottom: 68px;
}
.incontainlogin img{
  width: 100%;
  max-width: 300px;
  height: auto;
  margin: 0 auto;
}
.logoleftlogin{
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
}
.headerlogin{
  text-align: center;
}
.headerlogin h2{
  display: inline-block;
  background: linear-gradient(
180deg, #b38c2c 20%, #e8da9b 50%, #b38c2c 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
  margin-top: 10px;
  font-size: 25px;
}
.wantregister{
  margin-top: 18px;
  font-weight: 100;
  color: white;
  text-align: center;
  font-size: 16px;
}
.wantregister a{
  font-weight: 400;
  color: white;
}
.incontainerlg{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 767.8px){

  .incontainerlg{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }
  .bginputlogin{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 30px 20px;
  }
  .insidectloginmb{
    position: relative;
    
  }
  .bginputlogin::before{
    display: none;
  }
}
.ic-lg-01,.ic-lg-02,.ic-lg-03{
  position: absolute;
  width: 90px;
  z-index: 999;
}
.ic-lg-01{
  width: 16%;
  top: -50px;
  left: -10px;
      -webkit-animation: x-sexy-up-down-loop 4s ease-in-out infinite;
    animation: x-sexy-up-down-loop 4s ease-in-out infinite;
}
.ic-lg-02{
  width: 20%;
  bottom: -70px;
  right: 0px;
      -webkit-animation: x-sexy-up-down 3s 1s infinite alternate;
    animation: x-sexy-up-down 3s 1s infinite alternate;
}
@media(max-width: 767px){
  .ic-lg-01{
  width: 15%;
  top: 11px;
  left: 20px;
}
.ic-lg-02{
  width: 19%;
  bottom: -49px;
  right: 20px;
}
}

@-webkit-keyframes x-sexy-up-down {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    to {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }
}

@keyframes x-sexy-up-down {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    to {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }
}

@-webkit-keyframes x-sexy-up-down-loop {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    50% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}


@keyframes x-sexy-up-down-loop {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    50% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}
/* Login  */







/* Input style */

.loginbtn{
  padding: 10px;
  border: none;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  background: transparent;
  border: 1px solid #ffe18c;
  color: #ffe18c;
}
.loginbtn.active{
  background: #e3bf5a;
  color: black;
  border: 1px solid #222222;
}
.inputstyle{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #00000038;
  border: none;
  border-radius: 10px;
  font-weight: 100;
  color: white;
  padding: 10px;
  padding-left: 35px;
  width: 100%;
  border: 1px solid #908585;
  box-shadow: 3px 3px 5px black;
}
.inputstyle:read-only {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #33333387;
  border: none;
  border-radius: 10px;
  color: white;
  padding-left: 35px;
}
.inputstyle::placeholder {
  color: #888;
  opacity: 1;
  /* Firefox */
}
.inputstyle:focus {
  background: #69625c91;
  border: none;
  color: white;
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  -webkit-background: #1e1c38b8;
  -webkit-box-shadow: 0 0 0px 1000px #6b7696b8 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.el-input{
  position: relative;
}
.el-input i{
  display: block;
  position: absolute;
  top: 15px;
  left: 12px;
  color: #ffe287;
}

/* Input style */



/* Register */







/*register Container*/


.stepregister{
  justify-content: center;
    align-content: center;
  display: flex;
  margin: 10px 0;
}
.stepregis{
  margin: 0 5px;
  border-radius: 20px;
  padding:0 10px;
  background: #2b2b2b;
}
.stepregis.active{
  background: #ffe57b;
  color: black;
}
.regisstep{
  display: none;
}
.re01{
  display: block;
}
.finishcontain{
  font-size: 25px;
  text-align: center;
  margin: 25px 0;
}


/* Register */




/* Bank */


.x-bank-choices-type .-outer-wrapper {
  display: grid;
  justify-content: center;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.x-bank-choices-type .-outer-wrapper .-input-radio {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.x-bank-choices-type .-outer-wrapper .-input-radio+.-label {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px 10px;
  position: relative;
  margin: 5px auto;
  border-radius: 50%;
  opacity: .75;
  transition: all .1s;
}
.x-bank-choices-type .-outer-wrapper .-input-radio:checked+.-label {
  opacity: 1;
}
.x-bank-choices-type .-outer-wrapper .-input-radio:checked+.-label .-logo {
  width: 45px;
  height: 45px;
}
.x-bank-choices-type .-outer-wrapper .-input-radio+.-label .-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all .1s;
}
.fa-check:before {
  content: "\f00c";
}
.x-bank-choices-type .-outer-wrapper .-input-radio:checked+.-label .fas.fa-check {
  display: flex;
  justify-content: center;
  align-items: center;
}

.x-bank-choices-type .-outer-wrapper .-input-radio+.-label .fas.fa-check {
  display: none;
  position: absolute;
  bottom: -2px;
  right: -1px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  background-color: #71dd8a;
}
/* Bank */







/* Home Dashboard */
.bgcententmain{
  padding: 20px;
  border-radius: 35px;
  font-size: 15px;
  font-weight: 200;
  position: relative;
  transition: all .4s;
  margin-top: 82px;
}
.bgcententmain::before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      bottom: 0;
      left: 0;
      /* border: 3px solid gold; */
      border-radius: 20px;
      /* background: url(../assets/images/mini.jpg) center center;  */
      background-repeat: no-repeat;
      background-size: 100% 100%;
}
@media (max-width:767px){
  .bgcententmain{
    margin-top: 86px;
  }
  .bgcententmain::before{
    display:none;
  }
}
.cthome{
  position: relative;
  z-index: 1;
}
.x-hr-border-glow {
    height: 1px;
    margin-top: 0;
    margin-bottom: 0;
    background: linear-gradient(
90deg,transparent,#ecbd7b 30%,#ecbd7b 70%,transparent);
    width: 101%;
    text-align: center;
}
/* Home Dashboard */


/* Section01 */
.section01{
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 50px;
  background: linear-gradient(152deg, #611b1eb0, #130205),url(../assets/images/01.jpg)top center;
  background-size: cover;
  border-top: none;
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
  position: relative;
  z-index: 9;
  backdrop-filter: blur(3px);
}
.section01 .x-hr-border-glow{
  margin-top: 45px;
  height: 2px;
  width: 90%;
}
.cardbox{
  width:100%;
  max-width:450px;
  margin:0 auto;
  margin-top: -70px;
}
.circlecredit{
  width: 100%;
  max-width:500px;
  height: 195px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-bottom: 10px;
  padding-top: 56%;
}
.circlecredit::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    bottom: 0;
    left: 0;
    border: 3px solid transparent;
    border-radius: 20px;
    background-image: url(/account/assets/img/visabet/bg/bg-card.png),linear-gradient( 180deg,#a78059 -30%,#f2d7a9 22%,#715236 72%,#f1bb73 112%);
    background-size: cover;
    background-origin: border-box;
    background-clip: content-box,border-box;
    box-shadow: 0 0 10px #000000db;
}
@media (max-width:767px){
  .circlecredit{
  }
}
.leftheadmoney{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  padding-top: 20px !important;
  position: absolute;
  bottom: 25px;
  left: 25px;
}
.rightheadmoney{
  position: absolute;
  text-align: center;
  padding: 10px 0 !important;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s;
}
@media (max-width:400px){
  .rightheadmoney{
  right: 20px;
}
}
.rightheadmoney .cursorp:hover i{
  color: white !important;
  text-shadow: 0 0 5px white, 0 0 15px #ebb352, 0 0 25px #ebb352;
}
.rightheadmoney img{
  width: 100%;
  max-width: 94px;
  box-shadow: 0 0 5px white, 0 0 15px green, 0 0 15px gold;
  border-radius: 100px;
  margin-bottom: 3px;
}

.playgametext{
   position:
   absolute;
   left: 50%;
   transform: translateX(-50%);
   color: /*#d7ff9a;*/ white;
   font-weight: 400;
   margin-top: -3px;
   white-space: nowrap;
   font-size: 20px;
   text-shadow: 0 0 10px white, 0 0 20px #307d00;
}
@media (max-width:500px){
  .playgametext{
       font-size: 4vw;
}
}
.rightheadmoney i{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #e5d090 !important;
  font-size: 40px;
  margin-left: 3px;
  display: none;
}
.incrcd{
  text-align: left;
  z-index: 1;
  color: #dedede;
  font-weight: 100;
  width: 100%;
  padding: 0 30px;
  padding-bottom: 20px;
  font-size: 19px;
}
.visabottom{
  z-index:1;
}
.visabottom img{
  width: 60px;
  height: auto;
  position: absolute;
  right: 15px;
  bottom: 15px;
  -webkit-filter: drop-shadow(0px 0px 5px rgb(0 0 0));
  filter: drop-shadow(0px 0px 5px rgb(0 0 0));
}
.incrcd b{
  display: block;
  font-weight: 300;
}
.incrcd b p{
  display: inline;
  font-weight: 300;
  font-family: 'Sarabun', sans-serif;
  font-style: italic;
}
.incrcd i{
  color: #ffbc2d;
}
.incrcd span{
  display: inline-block;
  font-size: 20px;
  font-weight: 300;
  color: #fbfc28;
  font-family: 'Sarabun', sans-serif;
  font-style: italic;
}
.logocrcd img{
  width: 180px;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.chipcard {
  z-index: 1;
}
.chipcard img{
  width:65px;
  position: absolute;
  left: 25px;
  top: 91px;
  transition:
  all .3s;
}

.nameband{
  position: absolute;
  width: 50%;
  right: 0;
  top: 7px;
  display: inline-block;
  background: linear-gradient( 
180deg, #b38c2c 20%, #e8da9b 50%, #b38c2c 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  margin-top: 10px;
  font-size: 25px;
}
.detailuser img{
  width: 27px;
  margin-top: auto;
  margin-left: -5px;
}
.detailuser ul{
  margin: 0;
  padding: 0;
}
.detailuser li{
  list-style: none;
  font-size: 16px;
  margin-bottom: 7px;
  font-weight: 100;
  white-space: nowrap;
}
.detailuser strong{
  width: 26px;
  display: inline-block;
  color: white;
}
.btnsec01{
  position: absolute;
  width: 49%;
  right: 10px;
  bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.btnsec01 button{
  font-size: 15px;
  font-weight: 200;
  margin-right: 5px;
  padding: 5px 8px;
  color: #ffeec7;
  background: transparent;
  border: none;
  border-radius: 10px;
  white-space: nowrap;
}
.btnsec01 button:nth-child(1){
  background: linear-gradient(to right,  #4fb35c  0%, #235040  100%);
  color: white;
  border: none;
  font-weight: 200;
}
.btnsec01 button:nth-child(2){
  background: linear-gradient(to right,  #b53150  0%, #793686  100%);
  color: white;
  border: none;
  font-weight: 200;
}
.btnsec01 button:nth-child(3){
  background: linear-gradient(to right,  #4e6e8f  0%, #304156  100%);
  color: white;
  border: none;
  font-weight: 200;
}
@media(max-width: 550px){
  .detailuser li{
    font-size: 3.2vw;
  }
  .btnsec01{
    width: 55%;
  }
  .btnsec01 button{
    font-size: 13px;
  }
}
@media(max-width: 500px){
  
  .detailuser strong{
  width: 6vw;
  display: inline-block;
  }
  
}
@media (max-width: 500px){
  .visabottom img{
  width: 50px;
}
  .chipcard img{
  width:13vw;
  top: 20vw;
}
  .section01 .x-hr-border-glow{
  margin-top: 70px;
  height: 2px;
}
  .detailuser img{
    width:6vw;
  }
  .detailuser{
    padding-top: 14px;
  }
  .btnsec01{
    width: 85%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .btnsec01 button{
    font-size: 3vw;
    font-weight: 100 !important;
    padding: 7px 0;
  }
  .section01{
  }
  .circlecredit{
    margin-top: -61px;
  }
  .logocrcd img {
      width: 45vw;
  }
  .incrcd{
    font-size: 16px;
  }
  .incrcd span{
    font-size: 18px;
  }
    .rightheadmoney img{
  max-width: 20vw;
}
  .leftheadmoney{
    bottom: 20px;
  }
}
@media (max-width:450px){
    .chipcard img{
  width:13vw;
  top: 18vw;
}
}
@media (max-width:415px){
    .chipcard img{
  width:13vw;
  top: 17vw;
}
    .leftheadmoney{
    bottom: 10px;
  }
    .incrcd{
    font-size: 15px;
  }
}
@media (max-height:675px){
  .circlecredit{
  }
  .section01 .x-hr-border-glow{
  margin-top: 42px;
  height: 2px;
}
  .btnsec01{
    bottom:10px;
  }
}
.logouticon{
  position:absolute;
  right: 0;
  top: 0;
  z-index:1;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
}
.logouticon:hover{
  text-shadow: 0 0 5px white, 0 0 15px #ff0404, 0 0 20px red;
}
.walletword {
  font-size: 16px;
  margin:4px 0;
  padding-left: 30px;
  position: relative;
}
.walletword i{
  color: white;
  margin-right:5px;
  font-size:25px;
  position:
  absolute;
  left: 0;
}


/* Effect Section01 */
#layers {
  overflow: hidden;
  top: 45%;
  width: 100%;
  z-index: 0;
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg)
}

#layers,
#layers .layer {
  height: 200px;
  position: absolute;
  top: 200px;
  left: -10px;
}

@media (max-width:575px){
  #layers,
#layers .layer {
  height: 200px;
  left: -10px;
  position: absolute;
  top: 311px;
}
}
#layers .layer {
  background-size: auto 100%;
  background-position: 50% 0;
  background-repeat: repeat-x;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 200vw
}

@-webkit-keyframes twinkle {
  0% {
    opacity: .3
  }
  to {
    opacity: 1
  }
}

@keyframes twinkle {
  0% {
    opacity: .3
  }
  to {
    opacity: 1
  }
}

@-webkit-keyframes layer-1 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  to {
    -webkit-transform: translate3d(-689px, 0, 0);
    transform: translate3d(-689px, 0, 0)
  }
}

@keyframes layer-1 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  to {
    -webkit-transform: translate3d(-689px, 0, 0);
    transform: translate3d(-689px, 0, 0)
  }
}

#layers .layer:first-child {
  -webkit-animation: twinkle 8s ease-in -5s infinite alternate, layer-1 60s linear infinite;
  animation: twinkle 8s ease-in -5s infinite alternate, layer-1 60s linear infinite
}

@-webkit-keyframes layer-2 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  to {
    -webkit-transform: translate3d(-699px, 0, 0);
    transform: translate3d(-699px, 0, 0)
  }
}

@keyframes layer-2 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  to {
    -webkit-transform: translate3d(-699px, 0, 0);
    transform: translate3d(-699px, 0, 0)
  }
}

#layers .layer:nth-child(2) {
  -webkit-animation: twinkle 3s ease-in -3s infinite alternate, layer-2 120s linear infinite;
  animation: twinkle 3s ease-in -3s infinite alternate, layer-2 120s linear infinite
}

@-webkit-keyframes layer-3 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  to {
    -webkit-transform: translate3d(-699px, 0, 0);
    transform: translate3d(-699px, 0, 0)
  }
}

@keyframes layer-3 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  to {
    -webkit-transform: translate3d(-699px, 0, 0);
    transform: translate3d(-699px, 0, 0)
  }
}

#layers .layer:nth-child(3) {
  -webkit-animation: twinkle 3.5s ease-in infinite alternate, layer-3 120s linear infinite;
  animation: twinkle 3.5s ease-in infinite alternate, layer-3 120s linear infinite
}

@-webkit-keyframes layer-4 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  to {
    -webkit-transform: translate3d(-480px, 0, 0);
    transform: translate3d(-480px, 0, 0)
  }
}

@keyframes layer-4 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  to {
    -webkit-transform: translate3d(-480px, 0, 0);
    transform: translate3d(-480px, 0, 0)
  }
}

#layers .layer:nth-child(4) {
  -webkit-animation: twinkle 4s ease-in -2s infinite alternate, layer-4 80s linear infinite;
  animation: twinkle 4s ease-in -2s infinite alternate, layer-4 80s linear infinite
}

@-webkit-keyframes layer-5 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  to {
    -webkit-transform: translate3d(-486px, 0, 0);
    transform: translate3d(-486px, 0, 0)
  }
}

@keyframes layer-5 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  to {
    -webkit-transform: translate3d(-486px, 0, 0);
    transform: translate3d(-486px, 0, 0)
  }
}

#layers .layer:nth-child(5) {
  -webkit-animation: twinkle 5.5s ease-in infinite alternate, layer-5 80s linear infinite;
  animation: twinkle 5.5s ease-in infinite alternate, layer-5 80s linear infinite
}

#layers .-falling-star {
  content: "";
  position: absolute;
  top: -50px;
  z-index: 100;
  width: 72px;
  height: 50px;
  background-size: 72px 50px;
}

#layers .-falling-star:nth-child(6) {
  -webkit-animation: x-shooting-star-1 10s infinite;
  animation: x-shooting-star-1 10s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  left: 70%
}

#layers .-falling-star:nth-child(7) {
  -webkit-animation: x-shooting-star-2 10s infinite;
  animation: x-shooting-star-2 10s infinite;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  left: 40%
}

#layers .-falling-star:nth-child(8) {
  -webkit-animation: x-shooting-star-2 10s infinite;
  animation: x-shooting-star-2 10s infinite;
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
  left: 90%
}

#layers .-falling-star:nth-child(9) {
  -webkit-animation: x-shooting-star-1 10s infinite;
  animation: x-shooting-star-1 10s infinite;
  -webkit-animation-delay: 9s;
  animation-delay: 9s;
  left: 55%
}

#layers .-falling-star:nth-child(10) {
  -webkit-animation: x-shooting-star-1 10s infinite;
  animation: x-shooting-star-1 10s infinite;
  -webkit-animation-delay: 13s;
  animation-delay: 13s;
  left: 20%
}

@-webkit-keyframes x-shooting-star-1 {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  15% {
    opacity: 0;
    -webkit-transform: translate3d(-270px, 180px, 0);
    transform: translate3d(-270px, 180px, 0)
  }
  to {
    opacity: 0
  }
}

@keyframes x-shooting-star-1 {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  15% {
    opacity: 0;
    -webkit-transform: translate3d(-270px, 180px, 0);
    transform: translate3d(-270px, 180px, 0)
  }
  to {
    opacity: 0
  }
}

@-webkit-keyframes x-shooting-star-2 {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  25% {
    opacity: 0;
    -webkit-transform: translate3d(-270px, 180px, 0);
    transform: translate3d(-270px, 180px, 0)
  }
  to {
    opacity: 0
  }
}

@keyframes x-shooting-star-2 {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  25% {
    opacity: 0;
    -webkit-transform: translate3d(-270px, 180px, 0);
    transform: translate3d(-270px, 180px, 0)
  }
  to {
    opacity: 0
  }
}

@-webkit-keyframes x-shooting-star-3 {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  5% {
    opacity: 0;
    -webkit-transform: translate3d(-270px, 180px, 0);
    transform: translate3d(-270px, 180px, 0)
  }
  to {
    opacity: 0
  }
}

@keyframes x-shooting-star-3 {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
  5% {
    opacity: 0;
    -webkit-transform: translate3d(-270px, 180px, 0);
    transform: translate3d(-270px, 180px, 0)
  }
  to {
    opacity: 0
  }
}


/* Effect Section01 */

/* Section01 */




/* DropDown Right Sec01 */




.menuright{
  position: absolute;
  right: 5px;
  top: 13px;
}
.menuright i{
  font-size:20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
}
.containsideright{
  font-size: 15px;
  background: #12121ab5;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  color: white !important;
  border-radius: 9px;
  left: -10px !important;
  box-shadow: 0 0 0 1.5px #ecbd7a, 0 0 15px black;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #ffffff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #3b3b3b;
}
.dropdown-item.active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: #4a4a4a78;
}
.containsideright i{
  font-size: 15px !important;
  width: auto;
  height: auto;
}

/* DropDown Right Sec01 */



/* Line Fixed */
.sideline{
  overflow: hidden;
  /* background: rgba(255,203,0,1); */
  position: fixed;
  top: 50%;
  right:0;
  text-align:center;
  font-size:12px;
  /* padding: 8px; */
  color:white;
  z-index: 1001;
  /* -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,1); */
  cursor: pointer;
}
.sideline i{
  font-size: 50px;
}
@media (max-width:817px) {
.sideline{top: 87%;}
}
@media (max-height:830px) {
.sideline{top: 65%;}
}
@media (max-height:730px) {
.sideline{top: 62%;}
}
@media (max-height:650px) {
.sideline{top: 60%;}
}
@media (max-width:450px){
  .sideline{
    font-size: 11px;
  }
  .sideline i{
  font-size: 37px;
}
}
/* Line Fixed */






/* Contain Main */


.containmain{
  width: 100%;
  max-width: 750px;
  position: relative;
  background: linear-gradient(98deg, #330a12ab, #330a0ad9),url(/assets/img/visabet/bg/03.jpg)center center;
  margin: 0 auto;
  margin-top: 4px;
  border-radius: 11px;
  padding: 20px 0px;
  padding-top: 20px;
  overflow: auto;
  margin-bottom: 10px;
}
@media (max-height:675px){
  .containmain{
  }
}
.backtohome{
  display: block;
  width: 100%;
  max-width: 700px;
  
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
  position: absolute;
  z-index: 50;
  left: 50%;
  transform: translateX(-50%);
}
.backtohome button{
  background: linear-gradient(153deg, #5f0609,#922B21);
  box-shadow: 0 0 10px black;
  border-radius: 20px;
  border:none;
  color:white;
  padding: 9px;
}
@media (max-width:767px){
  .backtohome{
    position:relative;
  }
}
/* Contain Main */



/* Section02 */

.section02{
  box-shadow: 0px 5px 5px #00000061;
}
.headersection{
  font-size: 17px;
  font-weight: 400;
  padding-left: 20px;
}
.headersection i{
  color: #ffca51;
  font-size: 25px;
  margin-right: 6px;
}
/* Section02 */


/* Promotion */


.containslide{
  padding: 0 0px;
  position: relative;
  margin-top: 10px;
  height: 318px;
}
@media (max-width:767px){
  .containslide{
  margin-top: 0px;
}
}
.swiper.prosw{
  width: 100%;
  height: 100%;
  padding-bottom: 48px;
  position: relative;
  padding-top: 10px;
}

.prosw .swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 100%;
  max-width: 260px;
  opacity: 1;
  position: relative;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.prosw .swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 13px;
}
.prosw .swiper-slide button{
  position: absolute;
  bottom: -43px;
  color: #f9de7a;
  border-radius: 5px;
  display: none;
  padding: 3px 10px;
  font-size: 15px;
  font-weight: 400;
  border: 1.5px #d5a96f solid;
  background: transparent;
}
 .prosw .swiper-slide button:nth-child(2){
   left:1!important;
 }
  .prosw .swiper-slide button:nth-child(3){
   right:0!important;
 }
.prosw .swiper-slide.swiper-slide-active button{
  display: inline-block;
}
.prosw .swiper-slide.swiper-slide-active{
  opacity: 1;
}
.prosw .swiper-slide.swiper-slide-active img{
  box-shadow: 2px 2px 10px black;
}
.btnslide{
  z-index: 5;
  left: 0;
  right: 0;
  top: 38%;
  height: 30px;
  width: 100%;
  position: absolute;
  transform: translateY(-50%);
}
.btnslide button{
  padding: 0;
  color: white;
  font-size: 48px;
  background: transparent;
  border: none;
  position: absolute;
}
.btnslide button:nth-child(1){
  left: 8px;
}
.btnslide button:nth-child(2){
  right: 8px;
}
@media (max-height:675px){
.prosw .swiper-slide {
  max-width: 229px;
}
}


/* Promotion */



/* Section03 */
.section03{
  margin-top: 20px;
  box-shadow: 0px 5px 5px #00000061;
}

/* Game Home Tab */
.customgametab{
  justify-content: center;
}
.customgametab img{
  width: 44px;
  display: block;
  margin: 0 auto;
}
.customgametab.nav-pills .nav-link {
    border-radius: 50px;
    margin: 0 5px;
    margin-bottom: 10px;
    padding: 15px 5px;
    box-shadow: 0 5px 7px 0 #000000;
    background: linear-gradient(112deg, #79181e, #35070c);
}
.customgametab.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  color: #fff;
  background: linear-gradient(to bottom, #e7d39d, #b19560);
}
.gridgame{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}
.ingridgame{
  width: 100%;
}
.iningridgame{
  width: 100%;
  padding: 0 5px;

}
.iningridgame img{
  width: 100%;
  transition: all .3s;
  cursor: pointer;
}
.iningridgame img:hover{
  transform: scale(1.1);
}
@media (max-width: 767px){
  .customgametab img{
    width:30px
  }
  .customgametab.nav-pills .nav-link {
  
margin: 0 5px;
  
margin-bottom: 12px;
  
padding: 5px 12px;
  
white-space: nowrap;
  
font-size: 13px;
  
box-shadow: 0 5px 7px 0 #000000;
  
background: linear-gradient(112deg, #79181e, #35070c);
}
  .gridgame{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
}
.headertab{
  text-align: center;
  margin: 10px 0;
  margin-top: 15px;
}
.headertab h2{
  font-size: 30px;
  display: inline-block;
    background: linear-gradient( 
180deg, #b38c2c 20%, #e8da9b 50%, #b38c2c 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}


      .gslidebg{
        background: linear-gradient(145deg, #651610d1, #25080dd4);
        border-radius: 20px;
        padding-top: 6px;
        height: 242px;
        box-shadow: inset 0 0 10px black;
      }
      @media (max-width:767px){
        .gslidebg{
          height: 208px;
        }
      }
      .swiper.gameslide{
        width: 100%;
        height: 100%;
        padding-bottom: 56px;
        position: relative;
        padding-top: 10px;
      }

      .gameslide .swiper-slide {
        text-align: center;
        font-size: 18px;
        width: 100%;
        max-width: 200px;
        opacity: 1;
        position: relative;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
      @media (max-width:767px){
        .gameslide .swiper-slide {
          max-width: 160px;
        }
      }
      .gameslide .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      .gameslide .swiper-slide button{
        position: absolute;
        bottom: -42px;
        left: 50%;
        transform: translateX(-50%);
        color: #ffffff;
        border-radius: 5px;
        display: none;
        padding: 3px 21px;
        font-size: 14px;
        white-space: nowrap;
        font-weight: 400;
        border: 1.5px #84100f solid;
        background: linear-gradient(136deg, #bb0c0a, #370101);
      }
      @media (max-width:767px){
        .gameslide .swiper-slide button{
          padding: 3px 13px;
          bottom: -39px;
        }
      }
      .infogame01{
        left: 4px !important;
        transform: translateX(0%) !important;
        background: linear-gradient(150deg,#decd36,#a07d15) !important;
        border: 1.5px #b69a21 solid !important;
      }
      .playgame01{
        right: 6px !important;
        left: auto !important;
        transform: translateX(0%) !important;
      }
      .creditgame{
        position: absolute;
        bottom: -4px;
        width: 100%;
        padding: 5px 0;
        background: #101010c9;
        color: #fff3c6;
        border-radius: 0;
        font-size: 15px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      .creditgame i{
        font-size: 17px;
        margin-right: 3px;
      }
      .gameslide .swiper-slide.swiper-slide-active button{
        display: inline-block;
      }
      .gameslide .swiper-slide.swiper-slide-active{
        opacity: 1;
      }
      .gameslide .swiper-slide.swiper-slide-active img{
      }

    @media (max-height:675px){
      .gameslide .swiper-slide {
        max-width: 229px;
      }
    }




/* Game Home Tab */


/* Section03 */






/* Section04 */
      .bccontainer{
        padding: 0 28px;
        margin-top: 10px;
        position: relative;
        height: 353.78px;
      }
      @media (max-width:767px){
        .bccontainer{
        margin-top: 0px;
        height: 280px;
      }
      }
      @media (max-width:600px){
        .bccontainer{
        margin-top: 0px;
        height: 230px;
      }
      }
      @media (max-width:490px){
        .bccontainer{
        margin-top: 0px;
        height: 180px;
      }
      }
      @media (max-width:390px){
        .bccontainer{
        margin-top: 0px;
        height: 165px;
      }
      }
      .broadcast .swiper {
        width: 100%;
        height: 100%;
      }

      .broadcast .swiper-slide {
        text-align: center;
        font-size: 18px;
        padding: 10px;
        

        /* Center slide text vertically */
        padding-bottom: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }

      .broadcast .swiper-slide img {
        display: block;
        width: 100%;
        height: 328px;
        object-fit: cover;
        border-radius: 20px;
        box-shadow: 0 0 10px black;
      }
      @media (max-width:767px){
        .broadcast .swiper-slide img {
        width: auto;
        max-height:250px;
        }
      }
      @media (max-width:600px){
        .broadcast .swiper-slide img {
        width: auto;
        max-height:200px;
        }
      }
      @media (max-width:490px){
        .broadcast .swiper-slide img {
        width: auto;
        max-height:150px;
        }
      }
      @media (max-width:390px){
        .broadcast .swiper-slide img {
        width: auto;
        max-height:135px;
        }
      }
      :root {
          --swiper-theme-color: #ffffff;
      }
      .swiper-pagination-bullet{
        background: white;
      }
      .broadcast .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 17px;
        left: 0;
        width: 100%;
      }
      .bccontainer .btnslide{
        top: 42%;
        transform: translateY(-50%);
      }

/* Section04 */






/* Menu Tab */

.menucontent{
  display: none;
}

.hidemenu{
  position: absolute;
  font-size: 25px;
  font-weight: bold;
  z-index: 3;
  padding: 0;
  top: 5px;
  right: 14px;
  border-radius: 20px;
  border: none;
  background: transparent;
  color: white;
}
.inmenucontent{
  border-radius: 20px;
  padding: 10px;
  width: 100%;
  max-width: 741px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
@media (max-width:767px){
  .inmenucontent{
    margin-top: -35px;
  }
}
.ininmnct{
  z-index: 2;
  position: relative;
}
.menuhead{
  text-align: center;
  margin-top: 5px;
  font-size: 22px;
  font-weight: 400;
}
.menuhead i{
  font-size: 25px;
  color: #ffce42;
}

.animate__animated{
  --animate-duration: .5s;
}
.bginputlogin .animate__animated.animate__fadeIn {
  --animate-duration: .5s;
}
.overlaymenu{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000000ad;
  z-index: 0;
}
/* Menu Tab */


/* Deposit */
.depositimg{
  margin: 15px 0;
  text-align: center;
}
.depositimg img{
  width: 140px;
}
.gridbank{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.ingridbank{

}
.bankimg{
  text-align: center;
}
.bankimg img{
  width: 100%;
  max-width: 90px;
}
.detailbank{
  text-align: center;
  font-weight: 100px;
  font-size: 14px;
}
.detailbank button{
  margin-top: 10px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #ffe18c;
  color: white;
  font-weight: 100;
  padding: 5px 10px;
}

/*DPS SECTION*/
.ctqrcode{
  padding:10px;
  text-align:center;
}
.ctqrcode img{
  width:80%;
  max-width:200px;
  border:4px solid white;
  border-radius:3px;
}
.banktabicon{
  width: 100%;
  max-width: 45px;
  display: block;
  margin: 0 auto;
}
.containdps{
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
.griddps{
  display: grid;
  grid-template-columns: 1fr;
}
.ingriddps{
  padding: 5px;
}
.iningriddps{
  text-align: center;
  font-size: 14px;
  color: #efefef;
  font-weight: 100;
}
.iningriddps span{
  text-align: center;
  font-size:16px;
  color: white;
  font-weight: 500;
}
.iningriddps img{
  width: 100%;
  margin-bottom: 5px;
  max-width: 70px;
}
.iningriddps button{
  margin-top: 5px;
  border: none;
  font-weight: 100;
  border-radius: 10px;
  color: white;
  background: transparent;
  border: 2px solid #d9ba8e;
  padding: 5px 10px;
}
.iningriddps button i{
  color: #fff4bc;
}
.leftdps{
  box-shadow: 3px -2px 7px #00000052;
  border-radius: 10px;
}
.leftdps .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background: linear-gradient(to bottom, #e7d39d, #b19560);
}
.leftdps .nav-pills .nav-link {
    padding: 20px 0;
    text-align: center;
    font-size: clamp(10px, 2.8vw, 16px);
    white-space: nowrap;
}
.leftdps .nav-pills .nav-link i{
  display:block;
  font-size: 40px;
}
@media (max-width: 440px){
  .iningriddps{
  text-align: center;
  font-size: 3.4vw;
  color: #ffffff;
  }
}
@media (max-width: 405px){
  .griddps{
  }
  .ingriddps{
    padding-bottom:  15px;
  }
  .iningriddps img{
    width: 67px;
  }

}
.headerbankdt{
  text-align: center;
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
}
.headerbankdt img{
  width: 40px;
}
.rulewd{
  width:100%;
  max-width: 500px;
  margin: 10px auto;
  font-weight: 100;
  /* background: #3cb371; */
  background: #133924;
  /* color: #fff0db; */
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0 0 10px black;
}
.rulewd.text-dark{
  font-weight:300;
}
.tabdepisitimg{
  text-align:center;
  margin:20px 0;
  margin-bottom: 5px;
}
.tabdepisitimg img{
   width:100%;
   max-width:300px;
}
.transfermoney{
  font-size: 15px;
  text-align: center;
}
.transfermoney img{
  width:100%;
  max-width: 60px;
  cursor: pointer;
  margin-bottom: 5px;
}
.label-filds{
  background: #dadfe3;
  margin-left: 10px;
  color: #000 !important;
  margin-bottom: -2px;
  font-size: 12px;
  border-radius: 4px 4px 0 0;
  padding: 2px 9px;
}


.bank-tf input[type=radio] {
  display: none;
}
.bank-tf input[type=radio]:not(:disabled) ~ label {
  cursor: pointer;
}
.bank-tf input[type=radio]:disabled ~ label {
  color: #bcc2bf;
  border-color: #bcc2bf;
  box-shadow: none;
  cursor: not-allowed;
}
.bank-tf label {
  display: inline-block;
  background: #3a0f0f;
  width: 100%;
  /* float: left; */
  margin-bottom: 6px;
  border-radius: 4px;
  padding: .2rem;
  text-align: center;
  box-shadow: 0px 0px 3px -1px rgb(0 0 0);
  position: relative;
}
.bank-tf img{
  right: 2px;
  position: absolute;
  width: 39px !important;;
  z-index: 111;
}
.bank-tf h2{
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 0;
  font-weight: 600;
}
.bank-tf p{
  font-size: 12px;
  margin-top: -1px;
  margin-bottom: 0;
  font-weight: 200;
}
.bank-tf input[type=radio]:checked + label {
  background: linear-gradient(162deg, #3cb371, #265a3d);
  color: white;
  box-shadow: 0 0 10px black;
}
.bank-tf  i{
  display:none;
  position:absolute;
  left:10px;
  top:50%;
  transform:translateY(-50%);
  font-size:25px;
  color: #97ff97;
}
.bank-tf input[type=radio]:checked + label i{
  display:block;
}
.boxaddbank {
      display: inline-block;
      background: linear-gradient(152deg, #9858ff, #983132);
      width: 100%;
      /* float: left; */
      margin-bottom: 6px;
      border-radius: 4px;
      padding: 0.73rem;
      text-align: center;
      box-shadow: 0px 0px 3px -1px rgb(0 0 0);
      position: relative;
      cursor: pointer;
}
/*END DPS SECTION*/
/* Deposit */







/*  history SECTION*/
.containhis{
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.containhislist{
  height: 400px;
  overflow: auto;
}
.containerhis{
  padding: 0 15px;
  font-size: 15px;
  white-space: nowrap;
}
.timehis{
  font-size: 11px;
  color: #d2d2d2;
}
.listht{
  box-shadow: 0 0 0px 2px #62986d;
  background: #0000004f;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 10px 0;
  font-size: 15px;
}
.listhtwd{
  box-shadow: 0 0 0px 2px #bd4c4c;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 15px;
  margin: 10px 0;
}
.containhis .nav-link.green.active{
  background: linear-gradient(204deg, #8bc34a, #134815);
}
.containhis .nav-link.red.active{
  background: linear-gradient(190deg, #c34a4a, #481313);
}
.containerhis table{
  width: 100%;
}
.containerhis td{
  width: 50%;
}
.containerhis td:nth-child(2){
  text-align: right;
}
@media (max-width:400px){
  .listht,
.listhtwd{
  font-size: 3.5vw;
}
}

/* history Section*/


/* Withdraw */
.selectwdbox{
  padding: 17px 0;
  text-align: center;
  background: linear-gradient(150deg, #942c2c, #3e0c0c);
  margin: 6px 0;
  margin-bottom: 15px;
  border-radius: 20px;
  font-size: 15px;
  box-shadow: 0 0 0 2px #d2b286;
  white-space: nowrap;
  cursor: pointer;
}
.selectwdbox i{
  display: block;
  font-size: 50px;
}
.selectwdbox img{
  height: 50px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
@media (max-width:430px){
  .selectwdbox{
    font-size: 3.5vw;
  }
}
.containdeposit,.nmwdcontain,.rtwdcontain{
  display: block;
}
.backwd{
  background: transparent;
  border: none;
  color: white;
  position: absolute;
  padding: 0;
  top: -5px;
  left: 10px;
  font-size: 30px;
  display: none;
}
.detailwd{
  width: 100%;
  max-width: 344px;
  background: linear-gradient(153deg, #9a2d2d, #4c1436);
  box-shadow: 0 0 10px black;
  padding: 5px 30px;
  padding-bottom: 10px;
  margin: 0 auto;
  color: #ffffff;
  margin-top: 5px;
  font-size: 14px;
  border-radius: 10px;
  white-space: nowrap;
}
.detailwdbottom{
  white-space: pre-line;
  text-align: center;
  font-weight: 100;
  font-size: 12px;
}
.detailwd button{
  padding: 3px 20px;
  border-radius: 10px;
  border: none;
  margin-top: 7px;
  background: transparent;
  border: 1px solid #ffe18c;
  color: #ffe18c;
  font-size: 15px;
  box-shadow: 2px 2px 5px black;
}
.detailwd span{
  font-weight: 300;
}
.detailwd img{
  width: 50px;
  margin-right: 10px;
}
.containdeposit.animate__animated.animate__flipInX{
  --animate-duration: .5s;
}
.selectcontainer.animate__animated.animate__flipInX{
  --animate-duration: .5s;
}

/* Withdraw */









/* Friends Menu */
.friendsimg{
  text-align: center;
}
.friendsimg img{
  width: 100%;
  max-width: 201px;
}
.levelfriend{
  width: 100%;
  max-width: 250px;
  margin: 10px auto;
  font-size: 13px;
}
.levelfriend td{
  padding: 3px 0;
}
.frienddetail{
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  font-size: 13px;
  background: black;
  padding: 10px;
  border-radius: 10px;
  margin-top: -10px;
}
.headdtf{
  text-align: center;
  z-index: 1;
  margin-top: -5px;
}
.detailaf{
  background: linear-gradient(to right,  #4e6e8f  0%, #304156  100%);
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}
.friendlink{
  padding: 6px 0;
  background: #682123 !important;
  cursor: pointer;
  border: 1px solid #d2a66e !important;
  box-shadow: 3px 3px 10px #00000063;
}
.friendtext{
  font-size: 13px;
  width: 100%;
  max-width: 345px;
  padding: 0 20px;
  margin: 9px auto;
  margin-bottom: 0;
}
.friendtext .el-input i{
  top: 7px;
  font-size: 24px;
  left: 3px;
}
.iconbankfrind{
  width: 30px !important;
}
/* Friends Menu */









/*COPY*/
.myAlert-top {
  position: fixed;
  width: 200px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  margin: auto;
  background-color: rgb(53 3 3 / 61%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  border-radius: 17px;
  box-shadow: 2px 3px 0 2px #00000085;
  padding: 10px;
  color: white;
  z-index: 9999;
  text-align: center;
}
.myAlert-top i{
  font-size: 50px;
  color: #4cd41f;
}


.alertcopy {
  display: none;
}
/*END COPY*/













/* SweetAlert2 */



.swal2-popup {
    background: #000000ad;
    backdrop-filter: blur(5px);
}
.swal2-popup::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 0;
    border: 3px solid transparent;
    border-radius: 10px;
    background-image: linear-gradient( 
348deg,#100406,#3e090c),linear-gradient( 
180deg,#a78059 -30%,#f2d7a9 22%,#715236 72%,#f1bb73 112%);
    background-origin: border-box;
    background-clip: content-box,border-box;
}
.swal2-title{
  color: white;
}
.swal2-html-container{
  color: white;
}
.swal2-styled.swal2-confirm{
  background: linear-gradient(136deg, #bb0c0a, #370101);
  border: 2px solid #fde97d;
  border-radius: 10px;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
    background: transparent !important;
}
.swal2-success-circular-line-right{
  background: transparent !important;
}
.swal2-icon.swal2-success .swal2-success-fix{
  background: transparent !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
    border: .25em solid rgb(164 219 134);
}
.swal2-title{
  z-index: 1;
}

/* SweetAlert2 */





/* Rule Modal */
.rulebtn{
  text-align: center;
}
.rulebtn button{
  padding: 13px;
  border: none;
  background: /*linear-gradient(45deg, #7e0b0f, #cf3036);*/linear-gradient(45deg,  #e0b85f  , #3cb371);
  color: white;
  border-radius: 5px;
  margin-top: 23px;
  transition: all .3s;
}
.rulebtn button i{
  font-size:20px
}
.rulebtn button:hover{
  background: #3cb371;
}
.modalcontent{
  background: linear-gradient(
348deg,#3cb371,#265a3d);
  font-weight: 100;
  font-size: 15px;
}
.modalcontent .headmodalcontent{
  color: #ffe18c;
  padding: 10px;
}
.modalcontent .headmodalcontent i{
  font-size: 23px;
  cursor: pointer;
}
.modalcontent .headmodalcontent i:hover{
  color: white;
}
.modalcontent .ruletext{
  white-space: pre-line;
  padding: 5px 1rem;
}
.modalcontent .ruletext h5{
  display: inline;
  font-weight: 300;
  font-size: 17px;
  color: #ffe18c;
}
.headmodalcontent{
  border: none;
}
.footermodalcontent{
  border: none;
}
/* Rule Modal */







/*Menu Grid*/
.gamegrid{
  width: 100%;
  max-width: 701px;
  margin: 0 auto;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.ingamegrid{
  padding: 10px;
}
@media (max-width:470px){
  .ingamegrid{
  padding: 5px;
}
}
.iningamegrid::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 22px; 
  padding: 3px; 
  background:linear-gradient(45deg,#b8860b,#eedc82,#b8860b,#ffffff); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}
.iningamegrid {
  background: linear-gradient(360deg,#000000,#3a0303);
  padding: 10px 10px;
  border-radius: 25px;
  /* border: 2px solid gold; */
  cursor: pointer;
  text-align: center;
  position: relative;
}
.iningamegrid:hover{
  border:2px solid white;
  box-shadow:0 0 5px white,0 0 10px #f2d845,0 0 20px #f2d845;
}
.iningamegrid span{
  margin-top:10px;
  display:block;
  white-space: nowrap;
  font-size: clamp(10px, 3.5vw, 16px);
  font-weight: 200;
}
.iningamegrid img{
  width: 50%;
}
@media (max-width: 630px){
  .gamegrid{
  grid-template-columns: 1fr 1fr 1fr;
}
}
@media (max-width: 400px){
  .gamegrid{
}
}
/*Menu Grid*/





/* List Game Grid */
.listgridgame{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 991px){
  .listgridgame{
  grid-template-columns:  1fr 1fr;
}
}
@media (max-width: 600px){
  .listgridgame{
  grid-template-columns:  1fr;
}
}
.inlistgame{
  padding: 10px;
}
.ininlistgame{
  border-radius: 10px;
  background: linear-gradient(348deg,#260409,#500a0e);

  padding: 5px;
}
.ininlistgame img{
  width: 100%;
  border-radius: 10px;
}
.badge{
  font-weight: 200;
}
.pgamebtn button{
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  border: none;
  color: black;
  background: linear-gradient(153deg, #ffd98c, #76581e);
}
/* List Game Grid */

/* ALERT */

.swiper-container-2{
  width: 100%;
  max-width: 1000px;
  height: auto;
  position: relative;
  margin: 0 auto;
  padding: 0 10px;
  overflow: auto;
  overflow: hidden;
  margin-top: 30px;
  padding-bottom: 40px;
}
.swiper-container-2 .swiper-slide{
  width: 100%;
  max-width: 500px;
  height: auto !important;
  box-shadow: 0 0 0;
  background-size: 100%;
  margin-top: 0 !important;
}
.swiper-container-2 .swiper-slide img{
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 10px;
}

.swiper-container-2 .swiper-button-next, .swiper-button-prev {
    transform: scale(.6);
    margin-top: -40px;
    font-weight: bold;
}
.swiper-pagination-bullet{
  background-color: gold;
  padding: 5px;
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 6px;
}
.swiper-container-2 .swiper-button-next{
  right: -5px;
}
.swiper-container-2 .swiper-button-prev{
  left: -5px;
}
@media (max-width: 500px){
  .swiper-container-2 .swiper-slide{
  width: 100%;
  height: auto !important;
  box-shadow: 0 0 0;
  background-size: 100%;
  margin-top: 0 !important;
}
}
/* END ALERT*/



/* Angpao Content */
.learnangpao {
  width:100%;
  max-width: 400px;
  padding:10px;
  margin:0 auto;
  max-height:400px;
  overflow:auto;
  background:#00000047;
  border-radius:20px;
}
.learnangpao img{
  width: 100%;
  border-radius: 20px;
  display: block;
  margin: 10px auto;
}
/* Angpao Content */






/* Event Content */
.eventbox{
  text-align: center;
  margin-top: 10px;
}
.eventbox img{
  width: 100%;
  max-width: 70px;
  height: auto;
}
.eventbox .box{
  background: linear-gradient( 348deg,#360009ba,#3cb371);
  padding: 5px;
  padding-top: 20px;
  border-radius: 10px;
  border: 2px solid #e8cb9a;
  cursor: pointer;
}
.eventbox .box .detail{
  margin-top: 16px;
  font-weight:100;
  font-size: clamp(10px, 3vw, 14px);
}
.eventbox .row .col-6{
  width: 100%;
  padding: 5px;
}
.eventbox .box .detail span{
  display:block;
  font-weight:500;
}

/* Event Content */






/* Bank WD */
.bankwd img{
  width: 100%;
  max-width: clamp(40px, 13vw, 60px);
  display:block;
  margin:0 auto;
}
.bankwd table{
  width: 100%;
}
.bankwd td{
  padding: 5px;
}
.bankwd .box{
  background: linear-gradient(157deg, #b90f0f, #540000);
  border: 2px solid;
  color: white;
  border-radius:10px;
  padding:5px 0;
  font-size: clamp(10px, 2.8vw, 16px);
  box-shadow:0 0 15px #00000078;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  cursor: pointer;
}
.bankwd .box .badge{
  color:white;
}
.addnewbankbtn{text-align: center;font-size: 15px;font-weight: 100;cursor: pointer;}
.bgaddnewbank{
  background:#342f2f;
  box-shadow:0 0 10px black
}
/* Bank WD */




/* Change Password */
.changepassword{
  display: none;
}
/* Change Password */


/* Line Login */
.linelogin{
  width:100%;
  max-width:500px;
  margin:0 auto;
}
.linelogin button{
  width: 100%;
  margin-top: 13px;
  border: none;
  border-radius: 10px;
  padding: 10px 0;
  background: #00b803;
  color: white;
}
.linelogin button i{
  font-size:20px;
}
/* Line Login */






/* QRCODE Payment */


/* HIDE RADIO */
.selectqrcode{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 10px ;
  margin-left: -15px;
  margin-right: -15px;
}
.selectqrcode label{
  margin: 10px 10px;
}
.selectqrcode [type=radio] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.selectqrcode [type=radio] + .inlabel img {
  cursor: pointer;
  width: 50px;
  filter: grayscale(1);
}
.selectqrcode [type=radio] + .inlabel span {
  display: block;
  margin-top: 7px;
  font-size: 13px;
}
.selectqrcode [type=radio] + .inlabel {
  padding:5px;
  cursor: pointer;
  height: 100%;
  outline: none !important;
}
/* CHECKED STYLES */
.selectqrcode [type=radio]:checked + .inlabel {
  outline: 2px solid #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px white, 0 0 15px white, 0 0 25px #ff9b1f6e, 0 0 35px #ffd7156e,inset 0 0 10px black;
}
.selectqrcode [type=radio]:checked + .inlabel img{
  filter: grayscale(0);
}

.thaiqrct{
  width: 100%;
  background: #113566;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 10px #000000ad;
}
.thaiqrct img{
  width: 100%;
  max-width: 120px;
}
.moneyqrcode{
  font-size: 25px;
  text-align: center;
}
/* QRCODE Payment */


/* HIDE RADIO */
.selectqrcode{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 10px ;
  margin-left: -15px;
  margin-right: -15px;
}
.selectqrcode label{
  margin: 10px 10px;
}
.selectqrcode [type=radio] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.selectqrcode [type=radio] + .inlabel img {
  cursor: pointer;
  width: 50px;
  filter: grayscale(1);
}
.selectqrcode [type=radio] + .inlabel span {
  display: block;
  margin-top: 7px;
  font-size: 13px;
}
.selectqrcode [type=radio] + .inlabel {
  padding:5px;
  cursor: pointer;
  height: 100%;
  outline: none !important;
}
/* CHECKED STYLES */
.selectqrcode [type=radio]:checked + .inlabel {
  outline: 2px solid #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px white, 0 0 15px white, 0 0 25px #ff9b1f6e, 0 0 35px #ffd7156e,inset 0 0 10px black;
}
.selectqrcode [type=radio]:checked + .inlabel img{
  filter: grayscale(0);
}

.thaiqrct{
  width: 100%;
  background: #113566;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 10px #000000ad;
}
.thaiqrct img{
  width: 100%;
  max-width: 120px;
}
.moneyqrcode{
  font-size: 25px;
  text-align: center;
}
/* QRCODE Payment */


.modal-backdrop {
  z-index: 0 !important;
}


    /* custom by n */
.box-coming-soon{
	position: relative;
	display: inline-block;
	cursor: not-allowed;
}

.box-coming-soon img {
	opacity: 0.5;
}

.box-coming-soon .text{
	position: absolute;
	z-index: 999;
	margin: 0 auto;
	left: 7px;
	right: 0;        
	text-align: center;
	top: 40%;
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
	width: 80%;
	padding: 10px;
	font-size:14px;
}

@keyframes shake {
	0% { transform: translate(1px, 1px) rotate(0deg); }
	10% { transform: translate(-1px, -2px) rotate(-1deg); }
	20% { transform: translate(-3px, 0px) rotate(1deg); }
	30% { transform: translate(3px, 2px) rotate(0deg); }
	40% { transform: translate(1px, -1px) rotate(1deg); }
	50% { transform: translate(-1px, 2px) rotate(-1deg); }
	60% { transform: translate(-3px, 1px) rotate(0deg); }
	70% { transform: translate(3px, 1px) rotate(-1deg); }
	80% { transform: translate(-1px, -1px) rotate(1deg); }
	90% { transform: translate(1px, 2px) rotate(0deg); }
	100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
  .img-shake {
	  margin: 5px;
  }

  .img-shake:hover {
	animation: shake 0.5s;
	animation-iteration-count: infinite;
  }
  
</style>
